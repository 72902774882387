<template>
  <div class="l-box">
    <div style="width: 100%; text-align: right; padding-right: 10px">
      <span style="margin-right: 10px; cursor: pointer" @click="returnLogin"
        >返回登录</span
      >
      <span style="cursor: pointer" @click="returnRegis" v-if="!isShow"
        >返回上一层</span
      >
    </div>
    <p class="plarfrom-name">账号注册</p>
    <div style="width: 100%" v-show="isShow">
      <div>
        <div>选择角色</div>
        <ul class="choose-role">
          <li
            :class="currentIndex == index ? 'active' : ''"
            v-for="(item, index) in roleList"
            :key="index"
            @click="chooseRole(item.val, index)"
          >
            {{ item.name }}
            <Icon
              v-if="currentIndex == index"
              style="
                font-weight: bold;
                position: absolute;
                top: 2px;
                right: 2px;
              "
              type="md-checkmark"
            />
          </li>
        </ul>
      </div>
      <!-- <plant-info :form="form" v-if="form.role == 30"></plant-info>
      <farmer-info :form="form" v-if="form.role == 40"></farmer-info>
      <social-info
        :form="form"
        v-if="form.role == 50 || form.role == 60"
      ></social-info> -->
    </div>
    <div v-show="isShow">
      <Form :label-width="80" ref="form" :model="form" :rules="rules">
        <FormItem prop="companyName" label="公司名称">
          <Input
            placeholder="请输入"
            v-model="form.companyName"
            clearable
          ></Input>
        </FormItem>
        <FormItem prop="name" label="联系人">
          <Input placeholder="请输入" v-model="form.name" clearable></Input>
        </FormItem>
        <FormItem prop="phone" label="电话号码">
          <Input
            placeholder="请输入手机号"
            v-model="form.phone"
            clearable
          ></Input>
        </FormItem>
        <FormItem prop="code" label="验证码">
          <Input placeholder="请输入验证码" v-model="form.code">
            <!-- <span
              slot="append"
              style="cursor: pointer"
              @click="sentCode"
              v-if="isSend"
              >获取验证码</span
            >
            <span slot="append" v-if="!isSend">{{ isPointer }}</span> -->
            <span slot="append">
              <Button @click="sentCode" :disabled="isSend">{{
                isPointer
              }}</Button>
            </span>
          </Input>
        </FormItem>
        <FormItem prop="password" label="密码">
          <Input
            password
            type="password"
            placeholder="请输入密码"
            v-model="form.password"
          ></Input>
        </FormItem>
        <FormItem prop="surePassword" label="确认密码">
          <Input
            password
            type="password"
            placeholder="请确认密码"
            v-model="form.surePassword"
          ></Input>
        </FormItem>
        <!-- <FormItem prop='region' label="地址">
          <Cascader
                :data="areaList"
                v-model="form.region"
                :load-data="loadData"
                change-on-select
                placeholder="请选择区域"
                style="width: 260px"
              ></Cascader>
        </FormItem> -->
        <FormItem prop="inDetail" label="详细地址">
          <Input
            placeholder="请输入详细地址"
            v-model="form.inDetail"
            clearable
          ></Input>
        </FormItem>
        <FormItem prop="ensure">
          <Checkbox v-model="form.ensure"
            >已同意<span style="color: #06baa1"
              >《畜禽粪污资源利用化平台用户协议》</span
            ></Checkbox
          >
        </FormItem>
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <Button
            type="primary"
            style="margin-bottom: 20px; width: 260px"
            @click="submit"
          >
            确认注册并登录
          </Button>
        </div>
      </Form>
    </div>
    <!-- <div style="width: 100%" v-show="!isShow">
      <div>
        <div>选择角色</div>
        <ul class="choose-role">
          <li
            :class="currentIndex == index ? 'active' : ''"
            v-for="(item, index) in roleList"
            :key="index"
            @click="chooseRole(item.val, index)"
          >
            {{ item.name }}
            <Icon
              style="
                font-weight: bold;
                position: absolute;
                top: 2px;
                right: 2px;
              "
              type="md-checkmark"
            />
          </li>
        </ul>
      </div>
      <plant-info :form="form" v-if="form.role == 30"></plant-info>
      <farmer-info :form="form" v-if="form.role == 40"></farmer-info>
      <social-info
        :form="form"
        v-if="form.role == 50 || form.role == 60"
      ></social-info>
    </div> -->
  </div>
</template>

<script>
import { V2 } from "@/common/http/api";
import axios from "axios";
import CU from "@/common/util/index";
import farmerInfo from "./components/farmer";
import plantInfo from "./components/planting";
import socialInfo from "./components/social";
import {Message} from "view-design";
export default {
  name: "",
  components: {
    farmerInfo,
    plantInfo,
    socialInfo,
  },
  data() {
    var SurePassword = (rule, value, callback) => {
      if (value !== "") {
        if (value !== this.form.password) {
          callback(new Error("密码不一致！"));
          return false;
        } else {
          callback();
        }
      }
    };
    return {
      isShow: true,
      //role name tel address password companyName
      form: {
        phone: "",
        code: "",
        password: "",
        surePassword: "",
        ensure: "",
        role: 30,
      },
      rules: {
        phone: [
          { required: true, message: " " },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: " " },
          {
            validator(rule, value, callback) {
              if (value.length >= 6) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "密码长度不得小于6位",
            trigger: "blur",
          },
        ],
        surePassword: [
          { required: true, message: "请确认账号密码", trigger: "blur" },
          { validator: SurePassword, trigger: "blur" },
        ],
        code: [{ required: true, message: " " }],
        ensure: [
          { required: true, message: " " },
          {
            validator(rule, value, callback) {
              if (value) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请勾选同意选项！",
            trigger: "blur",
          },
        ],
        companyName: [{ required: true, message: "请填写公司名称" }],
        name: [{ required: true, message: "请填写联系人" }],
        // region:[{required:true,message:"请选择地址区域"}],
        inDetail: [{ required: true, message: "请输入详细地址" }],
      },
      roleList: [
        {
          val: 30,
          name: "种植户",
        },
        {
          val: 40,
          name: "养殖户",
        },
        {
          val: 50,
          name: "社会化组织",
        },
        {
          val: 60,
          name: "集散点",
        },
      ],
      currentIndex: 0,
      isPointer: "获取验证码",
      //可以点击
      isSend: false,
      //省市区列表
      areaList: [],
    };
  },
  methods: {
    returnLogin() {
      this.$emit("returnLogin");
    },
    next() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.isShow = false;
      });
    },
    returnRegis() {
      this.isShow = true;
    },
    //发送验证码
    sentCode() {
      if (!this.form.phone) {
        this.$Message.error("请填写手机号");
        return;
      }
      if (!CU.validatePhone(this.form.phone)) return;
      // if(res) return
      axios({
        url: V2 + this.$api.REGISTER.CODE,
        method: "post",
        data: {
          phone: this.form.phone,
        },
      }).then(r => {
        if (r.data.code === 200) {
          if (!this.isSend) {
            this.isSend = true;
            let n = 31;
            let timer = setInterval(() => {
              n--;
              this.isPointer = n + "s之后重新获取";
              if (n == 0) {
                clearInterval(timer);
                this.isSend = false;
                this.isPointer = "获取验证码";
              }
            }, 1000);
          }
        } else {
          Message.error({ content: r.data?.message ?? '请求好像出错了，请重新尝试', duration: 1.5 });
        }
      })

    },
    //选择角色
    chooseRole(val, index) {
      this.form.role = val;
      this.currentIndex = index;
    },
    // getAreaList() {
    //   this.$post(this.$api.AREA_MANAGE.LIST, {
    //     parentId: "500153",
    //   }).then((res) => {
    //     res.forEach((item) => {
    //       item.label = item.name;
    //       item.value = item.id;
    //       item.loading = false;
    //       item.children = [];
    //     });
    //     this.areaList = res;
    //   });
    // },
    // loadData(item, callback) {
    //   item.loading = true;
    //   this.$post(this.$api.AREA_MANAGE.LIST, {
    //     parentId: item.id,
    //   })
    //     .then((res) => {
    //       res.forEach((item) => {
    //         item.label = item.name;
    //         item.value = item.id;
    //         if (item.hasChild == "true") {
    //           item.loading = false;
    //           item.children = [];
    //         }
    //       });
    //       item.children = res;
    //       callback();
    //     })
    //     .finally(() => {
    //       item.loading = false;
    //     });
    // },
    submit() {
      const chars = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';
      let len = 6;
  　　let maxPos = chars.length;
  　　let start = '';
  　　for (let i = 0; i < len; i++) {
  　　　　start += chars.charAt(Math.floor(Math.random() * maxPos));
  　　}

      this.$refs.form.validate().then((res) => {
        if (!res) return;
        axios({
          method: "post",
          url: V2 + this.$api.REGISTER.CHECKS_CODE,
          data: {
            phone: this.form.phone,
            code: this.form.code,
          },
        }).then((res) => {
          if (res.data.data) {
            let params = {};
            params.name = this.form.name;
            params.role = this.form.role;
            params.tel = this.form.phone;
            params.address = this.form.inDetail;
            params.companyName = this.form.companyName;
            params.password = start+window.btoa(this.form.password)
            //params.password = "123456";
            axios({
              method: "post",
              url: V2 + this.$api.REGISTER.REGIS,
              data: params,
            }).then(() => {
              this.$Message.success("注册成功！请返回登录界面登录！");
              this.returnLogin();
            });
          } else {
            this.$Message.error("验证码输入错误！");
          }
        });
      });
    },
  },
  mounted() {
    //  this.getAreaList()
  },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-input-group-append .ivu-btn,
.ivu-input-group-prepend .ivu-btn {
  box-shadow: none;
}
/deep/ .ivu-btn.disabled,
.ivu-btn[disabled] {
  cursor: not-allowed;
  color: #999;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.l-box {
  width: 400px;
  padding: 10px;
  margin: 0 0 0 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #cde6f8;
  border-radius: 33px;
  .logo {
    margin-top: 20px;
    width: 124px;
    height: 68px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .plarfrom-name {
    font-size: 24px;
    padding: 25px 0;
    font-weight: bold;
    color: #333;
    letter-spacing: 5px;
  }
  .btn {
    width: 260px;
  }
  .register {
    width: 260px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    span {
      font-size: 12px;
      cursor: pointer;
      // color: #d3d3d3;
    }
  }
}
.choose-role {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 60px;
  li {
    display: inline-block;
    width: 85px;
    height: 100%;
    line-height: 48px;
    text-align: center;
    border-radius: 4px;
    background-color: #f4f5f5;
    cursor: pointer;
  }
  .active {
    background-color: rgba(6, 186, 161, 0.2);
    color: #4dcbb9;
    position: relative;
  }
}
/deep/ .ivu-form {
  width: 360px !important;
}
</style>

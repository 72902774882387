<template>
  <div>
    <Form
      :label-width="100"
      style="width: 100%"
      ref="socialForm"
      :model="socialForm"
      :rules="rules"
    >
      <FormItem label="公司名称" prop="name">
        <Input
          clearable
          placeholder="请输入公司名称"
          style="width: 260px"
          v-model="socialForm.name"
        ></Input>
      </FormItem>
      <FormItem label="公司电话" prop="tel">
        <Input
          clearable
          placeholder="请输入电话号码"
          style="width: 260px"
          v-model="socialForm.tel"
        ></Input>
      </FormItem>
      <FormItem label="业务范围">
        <Select style="width: 260px" clearable placeholder="请选择业务范围">
          <Option
            v-for="(item, index) in scopeList"
            :key="index"
            :value="item.code"
            >{{ item.name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="公司地址" prop="serviceAddress">
        <Input
          clearable
          placeholder="请输入公司地址"
          style="width: 260px"
          v-model="socialForm.serviceAddress"
        >
          <span
            slot="append"
            class="iconfont icon-weizhi"
            style="cursor: pointer"
          ></span>
        </Input>
      </FormItem>
      <div style="width: 100%; text-align: center">
        <Button type="primary" style="width: 260px; margin-bottom: 20px">
          确认注册并登录
        </Button>
      </div>
    </Form>
  </div>
</template>
<script>
import { V2 } from "@/common/http/api";
import axios from "axios";
import CU from "@/common/util/index";
export default {
  name: "",
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      socialForm: {
        //业务范围
        businessScope: "",
        //组织名称
        name: "",
        //电话
        tel: "",
        //组织地址
        serviceAddress: "",
        //坐标
        thirdLongLat: "",
      },
      rules: {
        name: [{ required: true, message: "请输入公司名称" }],
        businessScope: [{ required: true, message: "请选择业务范围" }],
        serviceAddress: [{ required: true, message: "请输入组织地址" }],
        tel: [
          { required: true, message: "请输入电话号码" },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
      },
      scopeList: [],
    };
  },
  methods: {
    //业务范围
    getScope() {
      axios({
        method: "post",
        url: V2 + this.$api.REGISTER.SCOPE,
        data: {
          pid: 255,
        },
      }).then((res) => {
        this.scopeList = res.data.data.list;
      });
    },
    submit() {
      this.$refs.socialForm.validate().then((res) => {
        if (!res) return;
        axios({
          method: "post",
          url: V2 + this.$api.REGISTER.CHECKS_CODE,
          data: {
            phone: this.form.phone,
            code: this.form.code,
          },
        }).then((res) => {
          if (res.data.data) {
            let params = { ...this.socialForm, ...this.form };
            axios({
              method: "post",
              url: V2 + this.$api.REGISTER.REGIS,
              data: params,
            }).then(() => {
              this.$Message.success("注册成功！请返回登录界面登录！");
            });
          } else {
            this.$Message.error("验证码输入错误！");
          }
        });
      });
    },
  },
  mounted() {
    // console.log(this.form);
    this.getScope();
  },
};
</script>

<style lang="less" scoped>
</style>